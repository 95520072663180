@font-face {
  font-family: 'Verdana';
  src: local('Verdana'),
    url('./font/verdana-font-family/verdana.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Verdana';
  src: local('Verdana'),
    url('./font/verdana-font-family/verdana-bold.ttf') format('truetype');
  font-style: bold;
}

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Verdana';
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sv-progress__text {
  display: none !important;
  /* padding-bottom: -2rem !important; */
}

.smallfont {
  font-size: 12px !important;
}

.smallfont b {
  font-size: 12.5px !important;
}

.smallfont h1 {
  font-size: 17px !important;
}

.smallfont h2 {
  font-size: 16px !important;
}

.smallfont h3 {
  font-size: 15px !important;
}

.smallfont h4 {
  font-size: 14px !important;
}

.smallfont h5 {
  font-size: 13.5px !important;
}

.smallfont .dc-homepage-body-summary-data {
  font-size: 13px !important;
}

.bigfont {
  font-size: 14px !important;
}

.bigfont b {
  font-size: 14px !important;
}

.bigfont h1 {
  font-size: 20px !important;
}

.bigfont h2 {
  font-size: 18px !important;
}

.bigfont h3 {
  font-size: 18px !important;
}

.bigfont h4 {
  font-size: 18px !important;
}

.bigfont h5 {
  font-size: 16px !important;
}

.bigfont .dc-patient-details-box p {
  font-size: 15px !important;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

p {
  font-size: inherit;
}

:root {
  /* patient css */
  --pa-primary: #e7d184;
  --pa-primary-light: rgba(240, 224, 191, 0.6);
  --pa-secondary: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  --pa-tertiary: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
  --pa-light: #c1dbfe80;
  --pa-border: #184788;
  /* dietitian css */
  --di-primary: #144284;
  --di-secondary: #008cfd;
  --di-tertiary: #5bc1e7;
  /* doctor css */
  --do-primary: #184788;
  --do-secondary: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  --do-tertiary: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
  --do-light: rgba(193, 219, 254, 0.5);
}

a.poster {
  color: #999 !important;
  background: rgba(249, 247, 247, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 80%;
  padding: 0.3rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.sv_qstn .sq-root {
  font-family: 'Verdana' !important;
  border-radius: 29px;
  padding: 7px;
  background-color: #d5bf99;
}

.sq-label {
  text-align: left;
}

.sq-root {
  text-align: center;
}

.hide-input {
  display: none;
}

.sq-root-bool {
  background-color: transparent !important;
}
.sq-root-text {
  text-align: justify !important;
}

.sq-title {
  font-family: 'Verdana' !important;
  font-size: 1rem !important;
  font-weight: bolder;
}

.sv_action_description {
  color: #000 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: bolder !important;
  text-align: center !important;
}
.sq-root-rating {
  background-color: #fff !important;
}

.sv-panel__icon {
  background-color: red;
}
.main_root {
  background-color: #fff !important;
  padding-bottom: 1rem;
  border-radius: 15px;
  margin-bottom: 0px;
  margin-top: -2rem;
}

.override-main-rating .override-root-rating {
  border-radius: 15px;
  padding: 7px;
  background-color: #d5bf99 !important;
  text-align: center;
}

.override-main-rating-m7 .override-root-rating-m7 {
  border-radius: 15px;
  padding: 7px;
  background-color: #ffffff !important;
  text-align: center;
}

.sv_qstn-action {
  margin-top: -1rem;
  border-radius: 10px;
  background-color: #ffffff;
}

.btn-primary-golden {
  background: #b5893f;
  border-radius: 15px;
}

.sv_description {
  text-align: center;
  font-weight: bolder;
  color: #000 !important;
}

.sv-footer__prev-btn {
  background-color: #b5893f !important;
  width: 100%;
  text-align: center !important;
  margin-top: -3rem;
}

.sv-footer__next-btn {
  background-color: #b5893f !important;
  text-align: center !important;
  width: 100%;
  float: none !important;
  margin-right: 0 !important;
}

.sv-footer__complete-btn {
  background-color: #b5893f !important;
  text-align: center !important;
  width: 98%;
}

img[alt='image'] {
  width: 100%;
  height: 120px;
  object-fit: contain;
  text-align: center;
}

.questionWithSubQuestionsImage {
  width: 100%;
  height: 100px;
  object-fit: contain;
  text-align: center;
}

.sq-item:nth-child(1) {
  margin-bottom: 5px;
}
.sq-root-action {
  text-align: center;
  padding-bottom: 1rem;
}
.sq-root-m5 {
  display: none;
}

.navigation-bar {
  padding: 1em;
}

.sv_action_description-m7 {
  text-align: left !important;
  font-weight: 400 !important;
  line-height: 1.2rem;
}

.progress-bar {
  background-color: #008cfd !important;
}

.main-landing-page {
  /* border: 1.13139px solid rgba(49, 49, 49, 0.4); */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 10px;
}

.each-logo-box {
  box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
}

video {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 14px;
}

.intro-header-navbar {
  width: 100%;
  position: relative;
  z-index: 100;
}

.intro-header-navbar p {
  margin: 0;
}

.intro-header-navbar-body {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px !important;
  font-weight: bold;
}

.intro-header-navbar-language p:first-of-type,
.intro-header-navbar-fontsize p:first-of-type {
  margin-right: 10px;
}

.intro-header-navbar-fontsize p:last-of-type {
  font-size: 18px !important;
  margin-bottom: -2.5px;
}

.intro-header-navbar-language,
.intro-header-navbar-fontsize {
  justify-content: center !important;
  align-items: flex-end !important;
}

.user_profile {
  color: #184788;
}

.user_image {
  width: 4rem;
}

.name {
  margin-left: 2rem;
}

.sv-question__content {
  font-size: 13px !important;
}
.sv-string-viewer img {
  font-weight: 900 !important;
  padding-bottom: 1rem;
}

.sv-title .sv-question__title .sq-title {
  font-weight: bold !important;
  font-size: 1.5rem !important;
}

.sv-question__title {
  text-align: center !important;
}

.sv_action_description {
  color: rgb(35, 35, 35) !important;
  font-size: 13.5px;
}

.whereby-video {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 14px;
}

.sv-root-modern .sv-checkbox--allowhover:hover .sv-checkbox__svg {
  fill: transparent !important;
  background-color: transparent !important;
  border: 3px solid rgba(64, 64, 64, 0.5) !important;
}

.small-screen-center {
  max-width: 550px !important;
  margin: 0 auto !important;
}

.button-small-screen {
  width: 30% !important;
}

.normal-line-height {
  line-height: 16px;
}

.sv-question__title {
  margin-top: 1.5rem !important;
}

.sv-question__description {
  font-size: 0.9rem;
}

.primary-button {
  background: #b5893f !important;
  border-radius: 15px !important;
  width: 100%;
  font-size: 16px !important;
}

.primary-button-bg-white {
  background: #fff !important;
  border-radius: 15px !important;
  width: 100%;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.primary-button-bg-white-2 {
  background: #fff !important;
  border-radius: 15px !important;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.primary-button-rewards {
  background: #b5893f !important;
  font-size: 14px !important;
  min-width: 120px;
  border-radius: 6px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
}
.cancel-button-rewards {
  font-size: 14px !important;
  min-width: 120px;
  border-radius: 6px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
}

.primary-button-bg-gray-rewards {
  background: #c7c5c5 !important;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.reward-box-bg {
  background: #f3f3f3;
  border: 1px solid #efefef;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.font-large-bold {
  font-size: 18px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;
}

.font-larger-abit {
  font-size: 18px;
}

.golden-text {
  color: #b5893f;
}

.header-yellow-text {
  font-size: 1.4rem;
  color: #b5893f;
  font-weight: 900;
}

/* anything that related to components */

@media only screen and (min-width: 480px) {
  .hamburger {
    font-size: 50px;
    padding-right: 20px;
    margin: 0;
    /* margin-top: -1rem; */
  }

  .menu-icon {
    font-size: 100px;
  }

  .desktop-wrapper {
    margin: 0 5%;
  }
}
